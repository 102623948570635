import axios from '@/api/index'

// 车辆年审列表   
export const Getnianlist = data => {
    return axios.request({
      url: `/auto/annual_checks`,
      method: 'post',
      data 
    })
  }

  // 年审信息录入添加  /auto/annual_check/add
  export const Gettian = data => {
    return axios.request({
      url: `/auto/annual_check/add`,
      method: 'post',
      data 
    })
  }

  // 删除年审记录
export const DelRefueling = params => {
  return axios.request({
    url: '/auto/annual_check/del',
    method: 'get',
    params
  })
}