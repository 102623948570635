<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>年审信息</el-breadcrumb-item>
      <el-breadcrumb-item>年审信息</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 内容开始 -->
    <el-card class="box-card">
      <!-- 头部 -->
      <div class="top">
        <el-button type="primary" @click="opentan()">添加信息</el-button>
        <div class="tops">
          <span>搜索车牌号：</span>
          <el-autocomplete
            :trigger-on-focus="false"
            placeholder="请输入内容"
            v-model="keys"
            :fetch-suggestions="salesperson"
            value-key="auto_number"
            @select="XiaohandleSelect($event, '车牌号')"
            clearable
            @clear="handleEmptyno"
          ></el-autocomplete>
        </div>
        <el-button class="boder" @click="laqulist">30天到期年审车辆 {{ auto_sum }} 辆</el-button>
        <el-button class="boder" @click="fan">返回</el-button>
        <!-- <div class="tops riqi">
          <span>日期：</span>
          <el-date-picker v-model="yue" type="month" @change="gettime" value-format="yyyy-MM" placeholder="选择月"></el-date-picker>
        </div> -->
      </div>

      <el-dialog title="年审信息录入" :visible.sync="dialogVisible" width="40%" :before-close="handleClose">
        <el-form ref="dform" :model="dform" label-width="100px" :rules="rules">
          <el-form-item label="车辆" label-width="150px">
            <el-autocomplete
              :trigger-on-focus="false"
              placeholder="请输入内容"
              v-model="key"
              :fetch-suggestions="salespersonss"
              value-key="auto_number"
              @select="XiaohandleSelectss($event, '车牌号')"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item label="年审费用" label-width="150px" prop="money">
            <div class="el-select"><el-input v-model="moneys" placeholder="请输入内容" @input="zhuan"></el-input></div>
          </el-form-item>
          <el-form-item label="年审办理日期" prop="check_time_at" label-width="150px">
            <el-date-picker value-format="yyyy-MM-dd" v-model="dform.check_time_at" type="date" placeholder="选择日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="下次年审日期" prop="next_time_at" label-width="150px">
            <el-date-picker value-format="yyyy-MM-dd" v-model="dform.next_time_at" type="date" placeholder="选择日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="年审附件" prop="files" label-width="150px">
            <el-upload
              accept=".jpg, .jpeg, .png"
              class="upload-demo"
              action=""
              :on-preview="handlePreview"
              :on-remove="
                (file, fileList) => {
                  handleRemove(file, fileList, this.refueling_filess)
                }
              "
              :http-request="httpRequest"
              :multiple="false"
              :limit="1"
              :on-exceed="handleExceed"
              :file-list="refueling_filess"
            >
              <el-button :disabled="addimgtype" size="small" type="primary" @click="upyupefun('年审附件')">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-row type="flex" class="row-bg" justify="center">
            <el-form-item>
              <el-button @click="cancelfun()">取 消</el-button>
              <el-button type="primary" @click="submitForm('dform')">添加</el-button>
            </el-form-item>
          </el-row>
          <!-- {{ dform }} -->
        </el-form>
      </el-dialog>
      <!-- 表格 -->
      <el-table :data="tableData" style="width: 70%" stripe>
        <el-table-column prop="auto_number" label="车牌号" width="180"></el-table-column>
        <el-table-column label="车辆类型" width="180">
          <template slot-scope="scope">
            <div id="test">{{ scope.row.type == 2 ? '管理车辆' : scope.row.get_use_type.tname }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="next_time_at" label="到期时间" width="180"></el-table-column>
        <el-table-column label="上次年审金额">
          <template slot-scope="scope">
            <div id="test">{{ scope.row.money / 100 + '元' }}</div>
          </template>
        </el-table-column>
        <el-table-column label="年审附件" width="200">
          <template slot-scope="scope">
            <el-image style="width: 100px; height: 100px" :src="scope.row.files" :previewSrcList="[scope.row.files]"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" icon="el-icon-delete" @click="delfun(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye'
import { GetautoList } from '../../../api/car'
import { Getnianlist, Gettian, DelRefueling } from '../../../api/nianshen'
import { Upimg, Daochu } from '@/api/upimg'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      tableData: [], // 列表数据
      auto_id: '',
      keys: '', // 搜索车辆
      key: '', // 车辆
      yue: '', // 月份选择器
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      moneys: '', // 金额
      addimgtype: false, // 上传控制
      dialogVisible: false, // 对话框
      key: '',
      ids: '', // 超出
      dform: {
        auto_id: '', // 车辆id
        money: '', // 年审费用（分）
        check_time_at: '', // 审核日期
        next_time_at: '', // 下次审核日期
        from_source: 'mobile',
        files: ''
      },
      auto_sum: '', // 30天到期车辆数量
      refueling_filess: [],
      rules: {
        // auto_id: [{ required: true, message: '请选择车辆', trigger: 'blur' }],
        check_time_at: [{ required: true, message: '请选择日期', trigger: 'blur' }],
        next_time_at: [{ required: true, message: '请选择日期', trigger: 'blur' }],
        // money: { type: 'number', required: true, message: '请填写年审费用', trigger: 'blur' },
        money: [
          { required: true, message: '年审费用不能为空', trigger: 'blur' },
          { type: 'number', message: '年审费用必须为数字值', trigger: 'blur' }
        ],
        files: { required: true, message: '请上传年审附件', trigger: 'change' }
      }
    }
  },
  mounted() {
    this.GetList() // 拉取列表
  },
  methods: {
    handleEmptyno(){
      this.auto_id = ''
      this.GetList()
    },
    fan() {
      this.GetList()
    },
    // 删除
    delfun(id) {
      // 删除
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delrefuelingfun(id)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 输入车辆匹配数据
    salesperson(queryString, cb) {
      this.getallfun(queryString, cb)
      this.GetList()
    },
    // 时间
    gettime(yue) {
      console.log(yue)
      //   this.GetList()
    },
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.GetList()
    },
    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.GetList()
    },
    // 搜索车辆选中
    XiaohandleSelect(e, term) {
      // console.log(e);
      this.auto_id = e.id
      this.GetList()
    },
    // 元转分
    zhuan(e) {
      this.dform.money = e * 100
    },
    // 打开弹窗
    opentan() {
      this.dialogVisible = true
    },
    // 关闭添加弹窗
    handleClose() {
      this.dialogVisible = false
      this.dform = {
        // files:'' // 保单附件
      }
      this.refueling_filess = []
      this.key = ''
      this.moneys = ''
    },
    salespersonss(queryString, cb) {
      this.getallfun(queryString, cb)
    },
    // 搜索车辆选中
    XiaohandleSelectss(e, term) {
      // console.log(e);
      this.dform.auto_id = e.id
    },
    // 上传
    handleChange(file, fileList) {
      console.log(file, fileList)
      // this.fileList = fileList.slice(-3)
    },
    // 添加取消
    cancelfun() {
      this.dform = {}
      this.key = ''
      this.moneys = ''
      this.refueling_filess = []
      this.dialogVisible = false
      this.$refs.dform.resetFields()
    },
    // 添加数据
    submitForm(dform) {
      this.$refs[dform].validate(valid => {
        if (valid) {
          // 成功
          this.Gettianjia(this.dform) // 添加用户信息
          this.GetList() // 添加成功之后重新拉取列表
          this.$refs[dform].resetFields()
        }
      })
    },

    // 删除图片
    handleRemove(file, fileList, re) {
      // re.forEach((item, i) => {
      //   if (item.uid === file.uid) {
      //     re.splice(i, 1)
      //   }
      // })
      this.dform.files = ''
      this.refueling_filess = []
    },
    handlePreview(file) {},
    handleExceed(files, fileList) {},
    // 切换上传条件
    upyupefun(v) {
      this.steupdata = v
    },
    // 图片上传
    async httpRequest(options) {
      console.log(options)
      this.addimgtype = true
      const fd = new FormData()
      fd.append('file[]', options.file)
      const res = await Upimg(fd)
      console.log(res.data[0].url)
      switch (this.steupdata) {
        case '年审附件':
          this.dform.files = res.data[0].url
          console.log(this.dform.files)
          break
      }
      this.addimgtype = false
      // // 图片上传成功后把图片push到scroll中用于修改
    },
    laqulist() {
      this.Getchao()
    },

    // 年审添加
    async Gettianjia(v) {
      const { data } = await Gettian(v)
      this.getanpostfun(data, '添加', this.GetList())
      console.log(data.code);
      if (data.code != 200) {
        // this.dform = {}
        // this.key = ''
        // this.moneys = ''
        // this.refueling_filess = []
        // this.dialogVisible = false
        // this.$refs.dform.resetFields()
        this.cancelfun()
      }
    },
    // 请求区
    async GetList() {
      const { data } = await Getnianlist({ auto_id: this.auto_id, ...this.pageData, status: 1 })
      console.log(data)
      this.tableData = data.data.list
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
      this.auto_sum = data.data.auto_sum
      this.ids = data.data.ids
      console.log(this.ids)
    },
    async Getchao() {
      const { data } = await Getnianlist({ ids: this.ids })
      this.tableData = data.data.list
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },
    // 获取车辆
    async getallfun(keys, cd) {
      const { data } = await GetautoList({ key: keys, page: 1, size: 100 })
      this.userlist = data.data.list
      cd(data.data.list)
    },
    // 删除
    async delrefuelingfun(id) {
      const { data } = await DelRefueling({ id })
      this.getanpostfun(data, '删除', this.GetList())
    },
    // 公共方法
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        // console.log(text)
        if (text !== '获取' && text !== '删除') {
          // console.log(123)
          this.handleClose()
        }

        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>

<style scoped>
.top {
  width: 690px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.boder {
  height: 40px;
  line-height: 40px;
  border: 1px solid #c0c4cc;
  border-radius: 5px;
  padding: 0px 10px;
  font-size: 14px;
}
</style>